import "onsenui/css/onsenui.css";
import "@/assets/onsen-css-components.css";

import Vue from "vue";
import VueOnsen from "vue-onsenui";
import App from "./App.vue";
import store from "./store";
import registerSW from "./registerServiceWorker";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueOnsen);
Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://b89e91d8c82542c6b92b57245d2f50dd@o365406.ingest.sentry.io/5907087",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

registerSW(store);

const vue = new Vue({
  store,
  render: (h) => h(App),
});
vue.$ons.platform.select("ios");
vue.$mount("#app");
