<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button />
      </div>
      <div class="center">手動で追加</div>
      <div class="right">
        <v-ons-toolbar-button @click="register()" :disabled="hasErrors"
          >登録</v-ons-toolbar-button
        >
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-item>
        <v-ons-input
          class="input"
          name="name"
          placeholder="名前"
          float
          v-model="name"
        />
      </v-ons-list-item>
      <v-ons-list-item>
        <v-ons-input
          class="input"
          type="number"
          placeholder="数量"
          float
          v-model="number"
        />
      </v-ons-list-item>
      <v-ons-list-item>
        <div class="left">
          <label>単位</label>
        </div>
        <div class="center">
          <v-ons-select class="select" v-model="unit">
            <option :value="''">未選択</option>
            <option :value="'個'">個</option>
            <option :value="'g'">g</option>
            <option :value="'ml'">ml</option>
          </v-ons-select>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>
<script>
import Top from "./Top.vue";

export default {
  data() {
    return {
      name: null,
      number: null,
      unit: "",
    };
  },
  computed: {
    hasErrors() {
      return !this.name || !this.number || !this.unit || this.unit == "";
    },
  },
  methods: {
    register() {
      this.$store.commit("items/add", {
        name: this.name,
        number: Number(this.number),
        unit: this.unit,
        isDone: false,
        menus: [],
      });
      this.$store.commit("navigator/shoppingList/replace", Top);
      this.$ons.notification.toast("買い物アイテムを登録しました。", {
        timeout: 2000,
      });
    },
  },
};
</script>
<style scoped>
.input {
  width: 100%;
}
.select {
  width: 100%;
}
</style>
