import { v4 as UUID } from "uuid";

export default {
  namespaced: true,
  state: [],
  mutations: {
    add(state, ingredient) {
      let ingredients = null;
      if (ingredient instanceof Array) {
        ingredients = ingredient;
      } else {
        ingredients = [ingredient];
      }

      ingredients.forEach((i) => {
        i.id = UUID();
        state.push(i);
      });
    },
    remove(state, id) {
      const index = state.findIndex((i) => i.id == id);
      state.splice(index, 1);
    },
    update(state, ingredient) {
      const index = state.findIndex((i) => i.id == ingredient.id);
      state.splice(index, 1, ingredient);
    },
  },
  actions: {},
  getters: {
    ingredientsByMenuId: (state) => (menuId) => {
      return state.filter((i) => i.menuId == menuId);
    },
  },
};
