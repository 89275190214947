<template>
  <v-ons-list-item :class="{ done: isDone }">
    <div class="left">
      <v-ons-icon class="draggable-icon handle" icon="ion-ios-menu" />
      <v-ons-checkbox :input-id="`checkbox-${item.id}`" v-model="isDone" />
    </div>
    <label class="center" :for="`checkbox-${item.id}`">
      <span>{{ item.name }}</span>
    </label>
    <div class="right">
      <v-ons-input
        class="number-input"
        type="number"
        v-model="number"
        placeholder="数量"
        float
      />
      <span>{{ item.unit }}</span>
      <v-ons-button modifier="quiet" @click="remove(item.id)">
        <v-ons-icon icon="ion-ios-trash" />
      </v-ons-button>
    </div>
  </v-ons-list-item>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      isDone: false,
    };
  },
  computed: {
    number: {
      get() {
        return this.item.number;
      },
      set(number) {
        const item = JSON.parse(JSON.stringify(this.item));
        item.number = Number(number);
        this.$store.commit("items/update", item);
      },
    },
  },
  methods: {
    async remove(id) {
      const ok =
        (await this.$ons.notification.confirm(
          "買い物アイテムを削除します。よろしいですか？"
        )) == 1;
      if (!ok) {
        return;
      }

      this.$store.commit("items/remove", id);
      this.$emit("showToast");
    },
  },
  created() {
    // FIXME props が watch できないため
    this.isDone = this.item.isDone;
  },
  watch: {
    isDone(to) {
      this.item.isDone = to;
      this.$store.commit("items/update", this.item);
    },
  },
};
</script>
<style scoped>
.number-input {
  width: 50px;
}

.draggable-icon {
  margin-right: 10px;
  padding: 10px;
}

.done {
  background-color: #d3d3d3;
  text-decoration: line-through;
}
</style>
