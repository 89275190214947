import ShoppingListTop from "../components/pages/shoppingList/Top.vue";
import MenuTop from "../components/pages/menu/Top.vue";
import SettingsTop from "../components/pages/settings/Top.vue";

export default {
  namespaced: true,
  modules: {
    shoppingList: {
      namespaced: true,
      state: {
        stack: [ShoppingListTop],
        options: {},
      },
      mutations: {
        push(state, page) {
          state.stack.push(page);
        },
        pop(state) {
          state.stack.pop();
        },
        replace(state, page) {
          state.stack = [page];
        },
      },
      actions: {},
    },
    menu: {
      namespaced: true,
      state: {
        stack: [MenuTop],
        options: {},
      },
      mutations: {
        push(state, page) {
          state.stack.push(page);
        },
        pop(state) {
          state.stack.pop();
        },
        replace(state, page) {
          state.stack = [page];
        },
        setOption(state, option) {
          state.options = option;
        },
      },
      actions: {},
    },
    settings: {
      namespaced: true,
      state: {
        stack: [SettingsTop],
        options: {},
      },
      mutations: {
        push(state, page) {
          state.stack.push(page);
        },
        pop(state) {
          state.stack.pop();
        },
        replace(state, page) {
          state.stack = [page];
        },
      },
      actions: {},
    },
  },
};
