import { v4 as UUID } from "uuid";
import arrayUniq from "array-uniq";

export default {
  namespaced: true,
  state: [],
  mutations: {
    add(state, item) {
      let items = null;
      if (!(item instanceof Array)) {
        items = [item];
      } else {
        items = item;
      }

      items.forEach((i) => {
        const same = state.find(
          (item) => item.name == i.name && !item.isDone && item.unit == i.unit
        );
        if (same) {
          // 同じ名前・単位の材料がある場合はマージする
          same.number += i.number;
          same.menus = arrayUniq(same.menus.concat(i.menus));
          const index = state.findIndex((item) => item.id == same.id);
          state.splice(index, 1, same);
          return;
        }

        i.id = UUID();
        state.push(i);
      });
    },
    remove(state, id) {
      const index = state.findIndex((i) => i.id == id);
      state.splice(index, 1);
    },
    update(state, item) {
      const index = state.findIndex((i) => i.id == item.id);
      state.splice(index, 1, item);
    },
    set(state, items) {
      // MEMO: 配列を再代入すると変更を検知しない為、 pop/push を使って実装している
      const times = state.length;
      for (let i = 0; i < times; i++) {
        state.pop();
      }
      items.forEach((item) => state.push(item));
    },
  },
  actions: {},
};
