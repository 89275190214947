<template>
  <v-ons-navigator swipeable :page-stack="pageStack"></v-ons-navigator>
</template>
<script>
export default {
  computed: {
    pageStack() {
      return this.$store.state.navigator.settings.stack;
    },
  },
};
</script>
