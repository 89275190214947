<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button />
      </div>
      <div class="center">メニュー</div>
      <div class="right"></div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-header>{{ menu.name }}</v-ons-list-header>
      <Ingredient
        @showToast="showToast()"
        :ingredient="ingredient"
        v-for="ingredient in ingredients"
        :key="ingredient.id"
      />
    </v-ons-list>
    <v-ons-fab position="bottom right" @click="goTo(input)">
      <v-ons-icon icon="md-plus"></v-ons-icon>
    </v-ons-fab>
    <v-ons-toast :visible.sync="toastVisible" animation="ascend">
      材料を削除しました。
    </v-ons-toast>
  </v-ons-page>
</template>
<script>
import Input from "./Input.vue";
import Ingredient from "../../../molecules/Ingredient.vue";

export default {
  components: {
    Ingredient,
  },
  data() {
    return {
      input: Input,
      toastVisible: false,
    };
  },
  computed: {
    menu() {
      return this.$store.state.navigator.menu.options.menu;
    },
    ingredients() {
      return this.$store.getters["ingredients/ingredientsByMenuId"](
        this.menu.id
      );
    },
  },
  methods: {
    goTo(page) {
      this.$store.commit("navigator/menu/push", page);
    },
    showToast() {
      if (this.toastVisible) {
        // 既に表示されている場合は、新たにトーストを表示しない
        return;
      }

      this.toastVisible = true;
      setTimeout(() => (this.toastVisible = false), 2000);
    },
  },
};
</script>
