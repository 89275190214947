<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button />
      </div>
      <div class="center">著作権情報</div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-item
        expandable
        :expanded.sync="isExpanded[license.name]"
        v-for="license in licenses"
        :key="license.name"
      >
        {{ license.name }}
        <div class="expandable-content">
          <pre class="license-text">
            {{ license.licenseText }}
          </pre>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>
<script>
import Licenses from "@/assets/licenses.json";

export default {
  data() {
    return {
      licenses: [],
      isExpanded: {},
    };
  },
  created() {
    this.licenses = Licenses;
  },
};
</script>
<style scoped>
.license-name {
  font-weight: bold;
  text-transform: none;
}

.license-text {
  width: 100%;
  white-space: pre-wrap;
}
</style>
