<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button />
      </div>
      <div class="center">メニュー</div>
      <div class="right">
        <v-ons-toolbar-button @click="register()" :disabled="hasErrors"
          >登録</v-ons-toolbar-button
        >
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-header>メニュー</v-ons-list-header>
      <v-ons-list-item>
        <v-ons-input class="input" placeholder="名前" v-model="name" float />
      </v-ons-list-item>

      <InputIngredient
        :id="id"
        v-model="inputIngredients[id]"
        @remove="removeInputIngredient(id)"
        v-for="id in Object.keys(inputIngredients)"
        :key="id"
      />
    </v-ons-list>
    <div></div>
    <div class="add-button-area">
      <v-ons-fab @click="addForm()">
        <v-ons-icon icon="md-plus"></v-ons-icon>
      </v-ons-fab>
    </div>
  </v-ons-page>
</template>
<script>
import { v4 as UUID } from "uuid";
import Top from "./Top.vue";
import InputIngredient from "../../../components/molecules/InputIngredient.vue";

export default {
  components: {
    InputIngredient,
  },
  data() {
    return {
      name: null,
      inputIngredients: { [UUID()]: {} },
    };
  },
  computed: {
    hasErrors() {
      if (!this.name) {
        return true;
      }

      for (let id of Object.keys(this.inputIngredients)) {
        const ingredient = this.inputIngredients[id];
        if (!ingredient.name) {
          return true;
        }

        if (!ingredient.number) {
          return true;
        }

        if (!ingredient.unit || ingredient.unit == "") {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    addForm() {
      this.$set(this.inputIngredients, UUID(), {});
    },
    register() {
      // FIXME commit で戻り値が戻せず、 menu.id が取得できないためこちらで指定している
      const menuId = UUID();
      this.$store.commit("menus/add", { id: menuId, name: this.name });
      const ingredients = Object.entries(this.inputIngredients).map(
        ([, ingredient]) => {
          return {
            menuId: menuId,
            name: ingredient.name,
            number: ingredient.number,
            unit: ingredient.unit,
          };
        }
      );
      this.$store.commit("ingredients/add", ingredients);
      this.$store.commit("navigator/menu/replace", Top);
      this.$ons.notification.toast("メニューを登録しました。", {
        timeout: 2000,
      });
    },
    removeInputIngredient(id) {
      this.$delete(this.inputIngredients, id);
    },
  },
};
</script>
<style scoped>
.add-button-area {
  position: relative;
}

.add-button-area > .fab {
  position: absolute;
  right: 10px;
  top: 10px;
}

.input {
  width: 100%;
}

.select {
  width: 100%;
}
</style>
