import { v4 as UUID } from "uuid";

export default {
  namespaced: true,
  state: [],
  mutations: {
    add(state, menu) {
      // FIXME mutation の return が取れないため
      if (!menu.id) {
        menu.id = UUID();
      }
      state.push(menu);
      return menu;
    },
    remove(state, id) {
      const index = state.findIndex((m) => m.id == id);
      state.splice(index, 1);
    },
    update(state, menu) {
      const index = state.findIndex((m) => m.id == menu.id);
      state.splice(index, 1, menu);
    },
    set(state, menus) {
      // MEMO: 配列を再代入すると変更を検知しない為、 pop/push を使って実装している
      const times = state.length;
      for (let i = 0; i < times; i++) {
        state.pop();
      }
      menus.forEach((menu) => state.push(menu));
    },
  },
  actions: {},
  getters: {
    findById: (state) => (menuId) => {
      return state.find((menu) => menu.id == menuId);
    },
  },
};
