<template>
  <v-ons-page>
    <v-ons-tabbar
      swipeable
      position="auto"
      :tabs="tabs"
      :visible="true"
      :index.sync="activeIndex"
    ></v-ons-tabbar>
  </v-ons-page>
</template>

<script>
import Menu from "./components/pages/menu/Navigator.vue";
import Settings from "./components/pages/settings/Navigator.vue";
import ShoppingList from "./components/pages/shoppingList/Navigator.vue";

export default {
  name: "app",
  data() {
    return {
      isVisibleForceUpdateDialog: false,
      activeIndex: 0,
      tabs: [
        {
          icon: "ion-ios-checkbox",
          label: "Shopping List",
          page: ShoppingList,
        },
        {
          icon: "ion-ios-book",
          label: "Menu",
          page: Menu,
        },
        {
          icon: "ion-ios-settings",
          label: "Settings",
          page: Settings,
        },
      ],
    };
  },
};
</script>
<style scoped>
.dialog-title {
  font-weight: bold;
}
</style>
