<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button />
      </div>
      <div class="center">メニューから追加</div>
      <div class="right">
        <v-ons-toolbar-button @click="register()"> 登録 </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-item v-for="menu in menus" :key="menu.id">
        <v-ons-checkbox
          class="left"
          :input-id="`checkbox-${menu.id}`"
          :value="menu.id"
          v-model="checkedMenus"
        />
        <label class="center" :for="`checkbox-${menu.id}`">{{
          menu.name
        }}</label>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>
<script>
import Top from "./Top.vue";

export default {
  data() {
    return {
      checkedMenus: [],
    };
  },
  computed: {
    menus() {
      return this.$store.state.menus;
    },
  },
  methods: {
    register() {
      const registerMenus = this.checkedMenus.map((checkedId) => {
        return this.$store.state.menus.find((menu) => menu.id == checkedId);
      });
      const registerItems = registerMenus
        .map((menu) => {
          return this.$store.getters["ingredients/ingredientsByMenuId"](
            menu.id
          );
        })
        .flat()
        .map((item) => {
          return {
            name: item.name,
            number: Number(item.number),
            unit: item.unit,
            isDone: false,
            menus: [item.menuId],
          };
        });
      this.$store.commit("items/add", registerItems);
      this.$store.commit("navigator/shoppingList/replace", Top);
      this.$ons.notification.toast("買い物アイテムを登録しました。", {
        timeout: 2000,
      });
    },
  },
};
</script>
