<template>
  <div>
    <v-ons-list-header>
      <div class="list-header left">材料</div>
      <div class="list-header right">
        <v-ons-button class="trash" modifier="quiet" @click="remove(id)">
          <v-ons-icon icon="ion-ios-trash" />
        </v-ons-button>
      </div>
    </v-ons-list-header>
    <v-ons-list-item>
      <v-ons-input
        class="input"
        @input="emitForm"
        placeholder="名前"
        float
        name="name"
      />
    </v-ons-list-item>
    <v-ons-list-item>
      <v-ons-input
        class="input"
        @input="emitForm"
        type="number"
        placeholder="数量"
        float
        name="number"
      />
    </v-ons-list-item>
    <v-ons-list-item>
      <div class="left">
        <label>単位</label>
      </div>
      <div class="center">
        <v-ons-select class="select" @change="emitForm" name="unit">
          <option value="">未選択</option>
          <option value="個">個</option>
          <option value="g">g</option>
          <option value="ml">ml</option>
        </v-ons-select>
      </div>
    </v-ons-list-item>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  data() {
    return {
      ingredient: {
        name: null,
        number: null,
        unit: "",
      },
    };
  },
  methods: {
    remove(id) {
      this.$emit("remove", id);
    },
    emitForm(e) {
      this.$set(this.ingredient, e.target.name, e.target.value);
      this.$emit("input", this.ingredient);
    },
  },
};
</script>
<style scoped>
.list-header.left {
  display: inline-block;
  width: 50%;
}
.list-header.right {
  display: inline-block;
  width: 50%;
}
.trash {
  float: right;
  margin-right: 10px;
}
.input {
  width: 100%;
}
.select {
  width: 100%;
}
</style>
