<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="center">メニュー</div>
    </v-ons-toolbar>
    <v-ons-list>
      <draggable v-model="menus" handle=".handle">
        <v-ons-list-item tappable v-for="menu in menus" :key="menu.id">
          <div class="left">
            <v-ons-icon class="draggable-icon handle" icon="ion-ios-menu" />
          </div>
          <div class="center" @click="goTo(detail, menu)">{{ menu.name }}</div>
          <div class="right">
            <v-ons-button modifier="quiet" @click="remove(menu.id)">
              <v-ons-icon icon="ion-ios-trash" />
            </v-ons-button>
          </div>
        </v-ons-list-item>
      </draggable>
    </v-ons-list>
    <v-ons-fab position="bottom right" @click="goTo(input)">
      <v-ons-icon icon="md-edit"></v-ons-icon>
    </v-ons-fab>
    <v-ons-toast :visible.sync="toastVisible" animation="ascend">
      メニューを削除しました。
    </v-ons-toast>
  </v-ons-page>
</template>
<script>
import Detail from "./detail/Top.vue";
import Input from "./Input.vue";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      detail: Detail,
      input: Input,
      toastVisible: false,
    };
  },
  computed: {
    menus: {
      get() {
        return this.$store.state.menus;
      },
      set(value) {
        this.$store.commit("menus/set", value);
      },
    },
  },
  methods: {
    async remove(id) {
      const ok =
        (await this.$ons.notification.confirm(
          "メニューを削除します。よろしいですか？"
        )) == 1;
      if (!ok) {
        return;
      }

      this.$store.commit("menus/remove", id);

      if (this.toastVisible) {
        // 既に表示されている場合は、新たにトーストを表示しない
        return;
      }

      this.toastVisible = true;
      setTimeout(() => (this.toastVisible = false), 2000);
    },
    goTo(page, menu) {
      this.$store.commit("navigator/menu/setOption", { menu: menu });
      this.$store.commit("navigator/menu/push", page);
    },
  },
};
</script>
<style scoped>
.list {
  margin-bottom: 100px;
}
.draggable-icon {
  margin-right: 10px;
  padding: 10px;
}
</style>
