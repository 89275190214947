<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button
          v-show="$store.state.system.update"
          @click="showUpdateNotification()"
        >
          <v-ons-icon class="update-notification" icon="fa-bell"></v-ons-icon>
        </v-ons-toolbar-button>
      </div>
      <div class="center">お買い物リスト</div>
      <div class="right">
        <v-ons-toolbar-button @click="showRemovePopover($event, 'down', true)">
          <v-ons-icon
            v-if="$ons.platform.isAndroid()"
            icon="md-more-vert"
          ></v-ons-icon>
          <span v-else>more</span>
        </v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <draggable v-model="items" handle=".handle">
        <Item
          @showToast="showToast()"
          :item="item"
          v-for="item in items"
          :key="item.id"
        />
      </draggable>
    </v-ons-list>
    <v-ons-fab position="bottom right" @click="showPopover($event, 'up', true)">
      <v-ons-icon icon="md-plus"></v-ons-icon>
    </v-ons-fab>
    <v-ons-popover
      cancelable
      :visible.sync="popoverVisible"
      :target="popoverTarget"
      :direction="popoverDirection"
      :cover-target="coverTarget"
    >
      <p style="text-align: center" @click="goTo(page.menu)">
        メニューから追加する
      </p>
      <p style="text-align: center" @click="goTo(page.manual)">
        手動で追加する
      </p>
    </v-ons-popover>
    <v-ons-popover
      cancelable
      :visible.sync="morePopoverVisible"
      :target="morePopoverTarget"
      :direction="morePopoverDirection"
      :cover-target="moreCoverTarget"
    >
      <p style="text-align: center" @click="copyShoppingList()">
        買い物リストをコピーする
      </p>
      <p style="text-align: center" @click="showSelectedMenus()">
        選択したメニューの表示
      </p>
      <p style="text-align: center; color: red" @click="removeCheckedAll()">
        チェック済みの項目を削除
      </p>
    </v-ons-popover>
    <v-ons-dialog cancelable :visible.sync="isDialogVisible">
      <div>
        <p
          style="text-align: center"
          v-for="menu in selectedMenus"
          :key="menu.id"
        >
          {{ menu.name }}
        </p>
      </div>
    </v-ons-dialog>
    <v-ons-toast :visible.sync="toastVisible" animation="ascend">
      買い物アイテムを削除しました。
    </v-ons-toast>
  </v-ons-page>
</template>
<script>
import Menus from "./Menus.vue";
import Manual from "./Manual.vue";
import Item from "../../molecules/Item.vue";
import draggable from "vuedraggable";
import arrayUniq from "array-uniq";
import jwt from "jsonwebtoken";
import password from "generate-password";
import CryptoJS from "crypto-js";

export default {
  components: {
    Item,
    draggable,
  },
  data() {
    return {
      popoverVisible: false,
      popoverTarget: null,
      popoverDirection: "up",
      coverTarget: false,
      morePopoverVisible: false,
      morePopoverTarget: null,
      morePopoverDirection: "up",
      moreCoverTarget: false,
      isDialogVisible: false,
      toastVisible: false,
      page: { menu: Menus, manual: Manual },
    };
  },
  mounted() {
    const params = new URL(location.href).searchParams;
    if (!params.has("s")) {
      return;
    }
    this.$ons.notification
      .prompt("追加用コードを入力してください", {
        title: "シェアされたアイテムの追加",
      })
      .then((secret) => {
        const token = params.get("s");
        let decoded = null;
        try {
          decoded = jwt.verify(token, secret);
        } catch (error) {
          this.$ons.notification
            .alert(
              "追加用コードが間違っている可能性があります<br>ご確認の上再度URLにアクセスしてください",
              { title: "追加の失敗" }
            )
            .then(() => {
              location.href = "/";
            });
          return;
        }
        this.$store.commit(
          "items/add",
          JSON.parse(
            CryptoJS.AES.decrypt(decoded.items, secret).toString(
              CryptoJS.enc.Utf8
            )
          )
        );
        this.$ons.notification
          .alert("シェアされたアイテムをお買い物リストに追加しました", {
            title: "お買い物リストへ追加",
          })
          .then(() => {
            location.href = "/";
          });
      });
  },
  computed: {
    items: {
      get() {
        return this.$store.state.items;
      },
      set(value) {
        this.$store.commit("items/set", value);
      },
    },
    selectedMenus() {
      const ids = arrayUniq(
        this.$store.state.items.map((item) => item.menus).flat()
      );
      return ids
        .map((id) => this.$store.getters["menus/findById"](id))
        .filter((menu) => menu);
    },
  },
  methods: {
    showPopover(event, direction, coverTarget = false) {
      this.popoverTarget = event;
      this.popoverDirection = direction;
      this.coverTarget = coverTarget;
      this.popoverVisible = true;
    },
    showRemovePopover(event, direction, coverTarget = false) {
      this.morePopoverTarget = event;
      this.morePopoverDirection = direction;
      this.moreCoverTarget = coverTarget;
      this.morePopoverVisible = true;
    },
    goTo(page) {
      this.popoverVisible = false;
      this.$store.commit("navigator/shoppingList/push", page);
    },
    removeCheckedAll() {
      var idList = [];
      this.$store.state.items.forEach((item) => {
        if (item.isDone == true) {
          idList.push(item.id);
        }
      });
      idList.forEach((id) => {
        this.$store.commit("items/remove", id);
      });
      this.morePopoverVisible = false;
    },
    showSelectedMenus() {
      this.morePopoverVisible = false;
      this.isDialogVisible = true;
    },
    showToast() {
      if (this.toastVisible) {
        // 既に表示されている場合は、新たにトーストを表示しない
        return;
      }

      this.toastVisible = true;
      setTimeout(() => (this.toastVisible = false), 2000);
    },
    async copyShoppingList() {
      const items = this.items.filter((item) => !item.isDone);

      const text = items
        .map((item) => `・${item.name} ${item.number}${item.unit}`)
        .join("\n");
      const secret = password.generate({
        length: 8,
        numbers: true,
      });
      const payload = {
        items: CryptoJS.AES.encrypt(
          CryptoJS.enc.Utf8.parse(JSON.stringify(items)),
          secret
        ).toString(),
      };
      const token = jwt.sign(payload, secret);
      await navigator.clipboard.writeText(
        `${text}\n\nお買い物リストに追加する場合は以下のURLをクリック\n${location.origin}?s=${token}\n追加用コード: ${secret}`
      );
      this.morePopoverVisible = false;

      // デフォルトの title は "Alert"。消したかったので空文字で上書き
      // https://ja.onsen.io/v2/api/vue/$ons.notification.html
      this.$ons.notification.alert("コピーしました。", { title: "" });
    },
    showUpdateNotification() {
      this.$ons.notification.alert("アプリを再起動してください。", {
        title: "アプリの更新",
      });
    },
  },
};
</script>
<style scoped>
.list {
  margin-bottom: 100px;
}
.update-notification {
  animation: vibration 3s;
}

@keyframes vibration {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(-3px, -0);
  }
  10% {
    transform: translate(3px, 0);
  }
  15% {
    transform: translate(-3px, -0);
  }
  20% {
    transform: translate(3px, 0);
  }
  25% {
    transform: translate(-3px, -0);
  }
  30% {
    transform: translate(3px, 0);
  }
  35% {
    transform: translate(-3px, 0);
  }
  40% {
    transform: translate(3px, 0);
  }
  45% {
    transform: translate(-3px, -0);
  }
  50% {
    transform: translate(3px, 0);
  }
  55% {
    transform: translate(-3px, -0);
  }
  60% {
    transform: translate(3px, 0);
  }
  65% {
    transform: translate(-3px, -0);
  }
  70% {
    transform: translate(3px, 0);
  }
  75% {
    transform: translate(-3px, -0);
  }
  80% {
    transform: translate(3px, 0);
  }
  85% {
    transform: translate(-3px, -0);
  }
  90% {
    transform: translate(3px, 0);
  }
  95% {
    transform: translate(-3px, -0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
