import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import Ingredients from "./ingredients";
import Items from "./items";
import Menus from "./menus";
import Navigator from "./navigator";

export default new Vuex.Store({
  modules: {
    ingredients: Ingredients,
    items: Items,
    menus: Menus,
    navigator: Navigator,
    system: {
      namespaced: true,
      state: {
        update: false,
      },
      mutations: {
        setUpdate(state, update) {
          state.update = update;
        },
      },
    },
  },
  plugins: [
    createPersistedState({ key: "sl:items", paths: ["items"] }),
    createPersistedState({ key: "sl:ingredients", paths: ["ingredients"] }),
    createPersistedState({ key: "sl:menus", paths: ["menus"] }),
  ],
});
