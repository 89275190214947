<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="center">設定</div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-item modifier="longdivider">
        <div class="left">バージョン</div>
        <div class="right">
          {{ version }}
        </div>
      </v-ons-list-item>
      <v-ons-list-item @click="goTo(license)" modifier="chevron" tappable
        >著作権情報</v-ons-list-item
      >
    </v-ons-list>
  </v-ons-page>
</template>
<script>
import License from "./License.vue";

export default {
  data() {
    return {
      license: License,
    };
  },
  computed: {
    version() {
      const commitRef = process.env.VUE_APP_COMMIT_REF;
      if (commitRef) {
        return commitRef.substring(0, 7);
      }

      return "0.0.0";
    },
  },
  methods: {
    goTo(page) {
      this.$store.commit("navigator/settings/push", page);
    },
  },
};
</script>
