<template>
  <v-ons-list-item>
    <div class="center">{{ ingredient.name }}</div>
    <div class="right">
      <v-ons-input
        class="number-input"
        type="number"
        v-model="number"
        placeholder="数量"
        float
      />
      <span>{{ ingredient.unit }}</span>
      <v-ons-button modifier="quiet" @click="remove(ingredient.id)">
        <v-ons-icon icon="ion-ios-trash" />
      </v-ons-button>
    </div>
  </v-ons-list-item>
</template>
<script>
export default {
  props: {
    ingredient: Object,
  },
  data() {
    return {
      number: null,
    };
  },
  methods: {
    async remove(id) {
      const ok =
        (await this.$ons.notification.confirm(
          "買い物アイテムを削除します。よろしいですか？"
        )) == 1;
      if (!ok) {
        return;
      }
      this.$store.commit("ingredients/remove", id);
      this.$emit("showToast");
    },
  },
  created() {
    // FIXME props を watch できなかったため
    this.number = this.ingredient.number;
  },
  watch: {
    // FIXME created で代入した時にも動いてしまう
    number: function (to) {
      this.ingredient.number = Number(to);
      this.$store.commit("ingredients/update", this.ingredient);
    },
  },
};
</script>
<style scoped>
.number-input {
  width: 50px;
}
</style>
